import { GetUserInfoCommand } from "~/commands/user"
import CardMaster from "~/masters/cards"

export type HeaderData = {
  crystal: {
    free: number,
    paid: number,
  },
  monthly_rank: string | null,
  user: object,
  user_class: string,
  target_year: string,
  card_master_updated_at: string,
}

const { data, pending, execute, status } = useLazyAsyncData<HeaderData>('header', async () => {
  const result = await new GetUserInfoCommand().execute() as HeaderData;
  await CardMaster.update(result.target_year, result.card_master_updated_at);
  return result;
}, { immediate: false });

export const useHeaderData = () => {
  const user = computed(() => data.value?.user ?? null)

  return {
    data,
    user,
    pending,
    isLoggedIn: computed(() => user.value && !Array.isArray(user.value)),
    reloadHeader: execute,
  }
}
